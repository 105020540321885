export type RoomProps = {
  id: number;
  active: boolean;
  name: string;
  externalId: string;
  createdAt: string;
  endedAt: string | null;
  updatedAt: string;
};

export type LocalStorageRoomProps = {
  roomId: string;
  createdAt: string;
};

export type RecordingsProps = {
  key: string;
  url: string;
  lastModified: string;
};

export enum RoleNames {
  HOST = 'host',
  GUEST = 'guest'
}

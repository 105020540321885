import React, { useRef } from 'react';
import { SelectDeviceField } from '../SelectDeviceField';
import { Portal } from 'react-portal';
import {
  selectDevices,
  selectLocalMediaSettings,
  useHMSActions,
  useHMSStore
} from '@100mslive/react-sdk';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-carbon/close';
import './style.css';

type ModalProps = {
  onClose: () => void;
};

export const Modal: React.FC<ModalProps> = ({ onClose }) => {
  const modalRef = useRef(null);

  useClickOutside(modalRef, onClose);

  const hmsActions = useHMSActions();

  const { audioInput, audioOutput } = useHMSStore(selectDevices);
  const currentDevices = useHMSStore(selectLocalMediaSettings);

  const handleAudioInputChange = (id: string) => {
    hmsActions.setAudioSettings({ deviceId: id });
  };

  const handleAudioOutputChange = (id: string) => {
    hmsActions.setAudioOutputDevice(id);
  };

  return (
    <Portal>
      <div className="modal-wrapper">
        <div ref={modalRef} className="modal">
          <div className="modal-header">
            <h2>Settings</h2>
            <button className="modal-header-close-btn" onClick={onClose}>
              <Icon icon={closeIcon} />
            </button>
          </div>
          <div className="modal-select">
            <h5 className="modal-select-label">Microphone</h5>
            <SelectDeviceField
              options={audioInput}
              value={currentDevices.audioInputDeviceId}
              onChange={handleAudioInputChange}
            />
          </div>
          <div className="modal-select">
            <h5 className="modal-select-label">Speakers</h5>
            <SelectDeviceField
              options={audioOutput}
              value={currentDevices.audioOutputDeviceId || ''}
              onChange={handleAudioOutputChange}
            />
          </div>
        </div>
      </div>
    </Portal>
  );
};

import api from '../../services/api.service';
import { useEffect, useState } from 'react';
import { RoomItem } from './RoomItem';
import { differenceInHours } from 'date-fns';
import { LocalStorageRoomProps, RoomProps } from '../../constants/types';
import { Loader } from '../../Loader';
import './style.css';

const MAX_RECORDING_VALIDITY_HOURS = 48;

export const RecordingsList = () => {
  const [userRooms, setUserRooms] = useState<RoomProps[] | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);

  const fetchRooms = async () => {
    setIsPending(true);

    const roomsArray = window.localStorage.getItem('rooms') || '[]';

    const filteredArray = JSON.parse(roomsArray).filter(
      (item: LocalStorageRoomProps) =>
        differenceInHours(new Date(), new Date(item.createdAt)) < MAX_RECORDING_VALIDITY_HOURS
    );

    localStorage.setItem('rooms', JSON.stringify(filteredArray));

    const roomIds = filteredArray.map((item: LocalStorageRoomProps) => item.roomId);

    const { data } = await api.getUserRooms({
      ids: JSON.stringify(roomIds)
    });

    setUserRooms(data.items);
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  useEffect(() => {
    if (userRooms) setIsPending(false);
  }, [userRooms]);

  return (
    <div className="recordings-list">
      <h2 className="list-title">List of previous recordings</h2>
      {!isPending ? (
        userRooms && userRooms.length ? (
          <ul className="accordion">
            {userRooms.map((room: RoomProps, index: number) => (
              <RoomItem key={index} room={room} />
            ))}
          </ul>
        ) : (
          <div>There are no recordings yet</div>
        )
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Loader style={{ borderTopColor: '#29a76b', width: '30px', height: '30px' }} />
        </div>
      )}
    </div>
  );
};

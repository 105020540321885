import React from 'react';
import './style.css';

type TextFieldProps = {
  value: string;
  name: string;
  label?: string;
  error: boolean;
  helperText?: string | boolean;
  type?: string;
};

export const TextField: React.FC<TextFieldProps> = ({
  value,
  name,
  label,
  error,
  helperText,
  type = 'text',
  ...other
}) => (
  <div className="TextField-container">
    <input
      className={`TextField-input ${error && 'TextField-error'}`}
      {...other}
      value={value}
      type="text"
      name={name}
      placeholder={label}
    />
    {error && helperText && <span className="TextField-error-text">{helperText}</span>}
  </div>
);

import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import api from '../../services/api.service';
import { Loader } from '../../Loader';
import * as React from 'react';
import { RecordingsProps, RoomProps } from '../../constants/types';

type RoomItemProps = {
  room: RoomProps;
};

export const RoomItem: React.FC<RoomItemProps> = ({ room }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [recordings, setRecordings] = useState<RecordingsProps[] | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);

  const contentEl = useRef<HTMLDivElement | null>(null);

  const { name } = room;

  const fetchRecordings = async () => {
    setIsPending(true);
    const { data } = await api.getRoomRecordings(room.externalId);

    setRecordings(data);
  };

  useEffect(() => {
    fetchRecordings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (recordings) setIsPending(false);
  }, [recordings]);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <li className={`accordion-item ${isOpen ? 'open' : ''}`}>
      <button className="accordion-item-btn" onClick={handleToggle}>
        {name}
        <span className="control-icon">{isOpen ? '-' : '+'} </span>
      </button>

      <div
        ref={contentEl}
        className="accordion-item-openned-wrapper"
        style={
          isOpen && contentEl.current
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <div className="accordion-item-content">
          {!isPending ? (
            recordings && recordings.length ? (
              recordings.map((recording: RecordingsProps, index) => (
                <a
                  href={recording.url}
                  target="_blank"
                  className="recording-link"
                  download
                  key={index}
                  rel="noreferrer"
                >
                  Recording: {format(new Date(recording.lastModified), 'MM/dd/yyyy, pp')}
                </a>
              ))
            ) : (
              <span>No results</span>
            )
          ) : (
            <Loader style={{ borderTopColor: '#29a76b' }} />
          )}
        </div>
      </div>
    </li>
  );
};

import { useRoutes } from 'react-router-dom';
import { ROUTES } from './constants/routes';
import { MainLayout } from './layouts/MainLayout';
import { MeetingRoom } from './components/MeetingRoom';
import { NotFound } from './pages/NotFound/NotFound';
import { Preview } from './components/Preview';
import { FinishMeeting } from './components/FinishMeeting';
import { Start } from './pages/Start';

function Router() {
  return useRoutes([
    {
      path: ROUTES.ROOT,
      element: <MainLayout />,
      children: [
        {
          path: ROUTES.ROOT,
          element: <Start />
        },
        {
          path: ROUTES.MEETING_ROOM,
          element: <MeetingRoom />
        },
        {
          path: ROUTES.PREVIEW,
          element: <Preview />
        },
        {
          path: ROUTES.FINISH_MEETING,
          element: <FinishMeeting />
        }
      ]
    },
    { path: '*', element: <NotFound /> }
  ]);
}

export default Router;

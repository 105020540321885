import React, { useCallback, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import chevronDown from '@iconify/icons-carbon/chevron-down';
import './style.css';
import { useClickOutside } from '../../hooks/useClickOutside';

type Option = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

interface SelectDeviceFieldProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
}

export const SelectDeviceField: React.FC<SelectDeviceFieldProps> = ({
  options,
  value,
  onChange
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useClickOutside(ref, () => setIsOpen(false));

  const getDeviceOptionLabel = useCallback(() => {
    if (options.length || value) {
      const currentDevice = options.find((option) => option.deviceId === value);
      return currentDevice?.label || value;
    }

    return 'No option';
  }, [options, value]);

  const handleOpen = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const handleChange = useCallback(
    (id: string) => {
      onChange(id);
      setIsOpen(false);
    },
    [onChange]
  );

  return (
    <div className="select-device-container" ref={ref}>
      <div className="select-device-input" onClick={handleOpen}>
        <span className="input-label">{getDeviceOptionLabel()}</span>
        <span className="input-icon">
          <Icon icon={chevronDown} style={{ transform: !isOpen ? 'none' : 'rotate(180deg)' }} />
        </span>
      </div>
      {isOpen && (
        <div className="select-device-list-wrapper">
          <ul className="select-device-list">
            {options.length ? (
              options.map((option, index) => (
                <li
                  key={index}
                  className="select-device-list-item"
                  onClick={() => handleChange(option.deviceId)}
                >
                  <span className="text-cut">{option.label}</span>
                </li>
              ))
            ) : (
              <div className="select-device-list-item">No option</div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

import { ROUTES } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import './style.css';

export const FinishMeeting = () => {
  const navigate = useNavigate();

  return (
    <div className="finish-meeting">
      <h2 className="finish-meeting-title">Your podcasting room - complete</h2>
      <span className="finish-meeting-description">
        Your recording is complete, you can download it from the list on the main page. This file
        will be only available for the next 48 hours.
      </span>
      <button className="finish-meeting-btn" onClick={() => navigate(ROUTES.ROOT)}>
        Go to main page
      </button>
    </div>
  );
};

import { JoinForm } from '../../components/JoinForm';
import { RecordingsList } from '../../components/RecordingsList';
import './style.css';

export const Start = () => {
  return (
    <div className="start-page">
      <JoinForm />
      <RecordingsList />
    </div>
  );
};

import React from 'react';
import { Loader } from '../../Loader';
import './style.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  loading?: boolean;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  style,
  children,
  loading,
  className,
  ...other
}) => {
  const ref = React.useRef<HTMLButtonElement | null>(null);

  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, [children]);

  return (
    <button
      {...other}
      ref={ref}
      style={
        loading
          ? {
              width: `${width}px`,
              height: `${height}px`,
              ...style
            }
          : style
      }
      className={`btn-primary ${className ? className : ''}`}
    >
      {loading ? <Loader /> : <div className="btn-text">{children}</div>}
    </button>
  );
};

import React from 'react';
import {
  HMSPeer,
  useVideo,
  selectPeerAudioByID,
  useHMSStore,
  selectIsPeerAudioEnabled,
  selectIsPeerVideoEnabled
} from '@100mslive/react-sdk';
import { Icon } from '@iconify/react';
import microphoneOffFilled from '@iconify/icons-carbon/microphone-off-filled';
import './style.css';

type PeerProps = {
  peer: HMSPeer;
};

export const Peer: React.FC<PeerProps> = ({ peer }) => {
  const { videoRef } = useVideo({
    trackId: peer.videoTrack
  });

  const fullName = peer.name.split(' ');
  const initials = (fullName?.shift()?.charAt(0) || '') + (fullName?.pop()?.charAt(0) || '');
  const shortName = initials.toUpperCase();

  const audioLevel = useHMSStore(selectPeerAudioByID(peer.id));
  const isPeerAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(peer.id));
  const isPeerVideoEnabled = useHMSStore(selectIsPeerVideoEnabled(peer.id));

  return (
    <div className="peer-container">
      <span className={`icon-muted ${!isPeerAudioEnabled && 'enabled'}`}>
        <Icon icon={microphoneOffFilled} />
      </span>
      <video
        ref={videoRef}
        className={`peer-video ${peer.isLocal ? 'local' : ''}${audioLevel ? ' active' : ''}${
          !isPeerVideoEnabled ? ' video-disabled' : ''
        }`}
        autoPlay
        muted
        playsInline
      />
      {!isPeerVideoEnabled && <div className="peer-label">{shortName}</div>}
      <div className="peer-name">
        {peer.name} {peer.isLocal ? '(You)' : ''}
      </div>
    </div>
  );
};

import { useCallback, useState } from 'react';
import { useAVToggle, useHMSStore, selectRoom, useHMSActions } from '@100mslive/react-sdk';
import { Icon } from '@iconify/react';
import videoFilled from '@iconify/icons-carbon/video-filled';
import videoOffFilled from '@iconify/icons-carbon/video-off-filled';
import microphoneFilled from '@iconify/icons-carbon/microphone-filled';
import microphoneOffFilled from '@iconify/icons-carbon/microphone-off-filled';
import phoneFilled from '@iconify/icons-carbon/phone-filled';
import settingsIcon from '@iconify/icons-carbon/settings';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../Modal';
import './style.css';

export const Toolbar = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { id } = useHMSStore(selectRoom);
  const hmsActions = useHMSActions();

  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } = useAVToggle();

  const handleDisableRoom = useCallback(async () => {
    await hmsActions.leave();

    navigate(`/room/${id}/finish`);
  }, [hmsActions, id, navigate]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div className="control-bar">
      <button className="btn-control" onClick={toggleAudio}>
        <Icon icon={isLocalAudioEnabled ? microphoneFilled : microphoneOffFilled} />
      </button>
      <button className="btn-control" onClick={toggleVideo}>
        <Icon icon={isLocalVideoEnabled ? videoFilled : videoOffFilled} />
      </button>
      <button className="btn-control" onClick={handleOpenModal}>
        <Icon icon={settingsIcon} />
      </button>
      <button className="btn-control btn-leave" onClick={handleDisableRoom}>
        <Icon className="icon-leave" icon={phoneFilled} />
      </button>
      {isModalOpen && <Modal onClose={handleCloseModal} />}
    </div>
  );
};

import { API_ROUTES } from '../constants/apiRoutes';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
});

const get = (methodUrl: string, params: any) => instance.get(methodUrl, { params });
const post = (methodUrl: string, params: any) => instance.post(methodUrl, params);

const schema = {
  generateAppToken: async (params = {}) => {
    try {
      const response: any = await post(API_ROUTES.APP_TOKEN, params);
      return response;
    } catch (error) {
      return error;
    }
  },
  generateManagementToken: async (params = {}) => {
    try {
      const response: any = await fetch(API_ROUTES.MANAGEMENT_TOKEN, params);
      return response.json();
    } catch (error) {
      return error;
    }
  },
  createRoom: async (params = {}) => {
    try {
      const response: any = await post(API_ROUTES.ROOM.ROOT, params);
      return response;
    } catch (error) {
      return error;
    }
  },
  disableRoom: async (params = {}) => {
    try {
      const response: any = await fetch(
        `${process.env.REACT_APP_API_URL}${API_ROUTES.ROOM.DISABLE}`,
        params
      );
      return response.json();
    } catch (error) {
      return error;
    }
  },
  getUserRooms: async (params = {}) => {
    try {
      const response: any = await get(API_ROUTES.ROOM.BY_USER, params);
      return response;
    } catch (error) {
      return error;
    }
  },
  getRoomRecordings: async (roomId: string, params = {}) => {
    try {
      const response: any = await get(`${API_ROUTES.ROOM.RECORDINGS}/${roomId}`, params);
      return response;
    } catch (error) {
      return error;
    }
  }
};

export default schema;

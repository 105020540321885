import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';
import './style.css';

export const MainLayout = () => {
  return (
    <div className="main-layout">
      <Header />
      <div className="layout-content-wrapper">
        <Outlet />
      </div>
    </div>
  );
};

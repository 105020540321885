import {
  selectIsConnectedToRoom,
  selectPeers,
  useHMSActions,
  useHMSStore,
  selectRoomID,
  selectLocalPeer
} from '@100mslive/react-sdk';
import { Peer } from '../Peer';
import './style.css';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toolbar } from '../Toolbar';
import { ROUTES } from '../../constants/routes';
import { Icon } from '@iconify/react';
import copyIcon from '@iconify/icons-carbon/copy';
import { generateInviteLink } from '../../utils/generateInviteLink';
import { LocalStorageRoomProps, RoleNames } from '../../constants/types';

export const MeetingRoom = () => {
  const navigate = useNavigate();

  const peers = useHMSStore(selectPeers);
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const roomId = useHMSStore(selectRoomID);
  const localPeer = useHMSStore(selectLocalPeer);

  const inviteLink = generateInviteLink(roomId);

  useEffect(() => {
    if (localPeer?.roleName === RoleNames.HOST && roomId !== '') {
      const roomIds = window.localStorage.getItem('rooms') || '[]';
      const updatedArray = JSON.parse(roomIds);

      const newObject = {
        roomId: roomId,
        createdAt: new Date()
      };

      const filteredArray = updatedArray.filter(
        (item: LocalStorageRoomProps) => item.roomId !== roomId
      );

      filteredArray.push(newObject);

      window.localStorage.setItem('rooms', JSON.stringify(filteredArray));
    }
  }, [localPeer?.roleName, roomId]);

  useEffect(() => {
    if (!isConnected) navigate(ROUTES.ROOT);
  }, [hmsActions, isConnected, navigate]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(inviteLink);
  }, [inviteLink]);

  return (
    <div className="conference">
      <div className="conference-info">
        <h2>Your podcasting room</h2>
        <div className="conference-description">
          You can send invites or share the link with your guests and start recording your podcast.
        </div>
        <div className="conference-invite" onClick={handleCopy}>
          {' '}
          Copy link:{' '}
          <div className="conference-invite-link" title="Copy to clipboard">
            <span>{inviteLink}</span>
            <Icon className="copy-icon" icon={copyIcon} />
          </div>
        </div>
      </div>
      <div className="peers-container">
        {peers.map((peer) => (
          <Peer key={peer.id} peer={peer} />
        ))}
      </div>
      <Toolbar />
    </div>
  );
};

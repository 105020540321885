import React, { CSSProperties } from 'react';
import './style.css';

type LoaderProps = {
  style?: CSSProperties;
};

export const Loader: React.FC<LoaderProps> = ({ style }) => (
  <div style={style} className="loader" />
);

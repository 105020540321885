import { Link } from 'react-router-dom';
import './style.css';

export const NotFound = () => {
  return (
    <div className="not-found-page">
      <h1 className="not-found-code">404</h1>
      <h3 className="not-found-title">Page not found.</h3>
      <span className="not-found-text">The page you are looking for might have been removed.</span>

      <Link to="/" className="link-btn">
        Return to website
      </Link>
    </div>
  );
};

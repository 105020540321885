import { useEffect, useState } from 'react';
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSNotifications,
  useHMSStore
} from '@100mslive/react-sdk';
import api from '../../services/api.service';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextField } from '../TextField/TextField';
import * as Yup from 'yup';
import { Button } from '../Button/Button';
import { RoleNames } from '../../constants/types';

type JoinFormProps = {
  name: string;
  roomName: string;
};

export const JoinForm = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [roomId, setRoomId] = useState<string>('');
  const notifications = useHMSNotifications();

  useEffect(() => {
    if (notifications?.type === 'ERROR') setIsLoading(false);
  }, [notifications, notifications?.type]);

  const formik = useFormik<JoinFormProps>({
    enableReinitialize: false,
    validateOnChange: true,
    initialValues: {
      name: '',
      roomName: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      roomName: Yup.string().required('Room name is required')
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        const {
          data: { externalId }
        } = await api.createRoom({
          name: values.roomName,
          description: 'Default'
        });

        if (externalId) {
          setRoomId(externalId);

          const {
            data: { token }
          } = await api.generateAppToken({
            userId: values.name,
            role: RoleNames.HOST,
            roomId: externalId
          });

          hmsActions.join({
            userName: values.name,
            authToken: token
          });
        }
      } catch (e) {
        setIsLoading(false);
      }
    }
  });

  const { handleSubmit, getFieldProps, touched, errors } = formik;

  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
        navigate('/');
      }
    };
  }, [hmsActions, isConnected, navigate]);

  useEffect(() => {
    if (isConnected && roomId) navigate(`/room/${roomId}/meeting`);
  }, [isConnected, navigate, roomId]);

  return (
    <FormikProvider value={formik}>
      <Form className="join-form" onSubmit={handleSubmit}>
        <h2 className="header-title">Start a podcasting room</h2>
        <span className="header-description">
          If you are the host of a podcast show, please proceed below to start your podcast
          recording room.
        </span>
        <div className="form-inputs">
          <TextField
            {...getFieldProps('name')}
            name="name"
            label="Your name"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            {...getFieldProps('roomName')}
            name="roomName"
            label="Room name"
            error={Boolean(touched.roomName && errors.roomName)}
            helperText={touched.roomName && errors.roomName}
          />
        </div>
        <Button type="submit" loading={isLoading}>
          Start a recording room
        </Button>
      </Form>
    </FormikProvider>
  );
};

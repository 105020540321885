import { ROUTES } from '../../constants/routes';
import './style.css';

export const Header = () => {
  return (
    <header>
      <div className="header">
        <a href={ROUTES.ROOT}>
          <img src="/logo.png" alt="Logo" />
        </a>
      </div>
    </header>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
import {
  selectIsConnectedToRoom,
  selectLocalPeer,
  useAVToggle,
  useHMSActions,
  useHMSStore,
  useVideo
} from '@100mslive/react-sdk';
import { Icon } from '@iconify/react';
import videoFilled from '@iconify/icons-carbon/video-filled';
import videoOffFilled from '@iconify/icons-carbon/video-off-filled';
import microphoneFilled from '@iconify/icons-carbon/microphone-filled';
import microphoneOffFilled from '@iconify/icons-carbon/microphone-off-filled';
import api from '../../services/api.service';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '../TextField/TextField';
import { Button } from '../Button/Button';
import settingsIcon from '@iconify/icons-carbon/settings';
import { Modal } from '../Modal';
import { RoleNames } from '../../constants/types';

export const Preview = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const localPeer = useHMSStore(selectLocalPeer);
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } = useAVToggle();

  useEffect(() => {
    if (isConnected) navigate(`/room/${id}/meeting`);
  }, [id, isConnected, navigate]);

  const { videoRef } = useVideo({
    trackId: localPeer?.videoTrack
  });

  const hmsActions = useHMSActions();

  useEffect(() => {
    api
      .generateAppToken({
        userId: ' ',
        role: RoleNames.GUEST,
        roomId: id
      })
      .then((res) => {
        hmsActions.preview({ userName: ' ', authToken: res.data.token });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: false,
    validateOnChange: true,
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required')
    }),
    onSubmit: async (values) => {
      const {
        data: { token }
      } = await api.generateAppToken({
        userId: values.name,
        role: RoleNames.GUEST,
        roomId: id
      });

      hmsActions.join({
        userName: values.name,
        authToken: token,
        rememberDeviceSelection: true,
        settings: {
          isAudioMuted: !isLocalAudioEnabled,
          isVideoMuted: !isLocalVideoEnabled
        }
      });
    }
  });

  const { handleSubmit, getFieldProps, touched, errors } = formik;

  const handleAudio = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleAudio && toggleAudio();
  };

  const handleVideo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleVideo && toggleVideo();
  };

  const handleOpenModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div className="preview">
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <div className="preview-container">
            <div className="video-container">
              <video
                className={`video ${!isLocalVideoEnabled ? ' video-disabled' : ''}`}
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
              <button className="preview-btn-control btn-settings" onClick={handleOpenModal}>
                <Icon icon={settingsIcon} />
              </button>
              {isModalOpen && <Modal onClose={handleCloseModal} />}
              <div className="preview-control-bar">
                <button className="preview-btn-control btn-audio" onClick={handleAudio}>
                  <Icon icon={isLocalAudioEnabled ? microphoneFilled : microphoneOffFilled} />
                </button>
                <button className="preview-btn-control btn-video" onClick={handleVideo}>
                  <Icon icon={isLocalVideoEnabled ? videoFilled : videoOffFilled} />
                </button>
              </div>
            </div>
            <h3 className="preview-title">Please type your name</h3>
            <TextField
              {...getFieldProps('name')}
              name="name"
              label="Your name"
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <Button type="submit">Join room</Button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

import { selectIsConnectedToRoom, useHMSStore, useHMSActions } from '@100mslive/react-sdk';
import './App.css';
import { useEffect } from 'react';
import Router from './routes';

function App() {
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const hmsActions = useHMSActions();

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) hmsActions.leave();
    };
  }, [hmsActions, isConnected]);

  return <Router />;
}

export default App;

export const API_ROUTES = {
  APP_TOKEN: '/auth/app',
  MANAGEMENT_TOKEN: '/auth/management',
  ROOM: {
    ROOT: '/room',
    DISABLE: '/room/disable',
    BY_USER: '/room/byUser',
    RECORDINGS: '/room/recordings'
  }
};
